import React from "react";

function HeroVideo() {
    return(
        <div className="hero-video">
            <video playsInline autoPlay muted loop poster="../assets/img/pexels-cover.jpg" id="bgvid">
                <source src='https://baselinesandbox.co.za/Jacob/aa/pexels-google-deepmind.mp4' type="video/mp4"/>
            </video>
        </div>
    )
}

export default HeroVideo;