import React from "react";
import aboutImg from "../assets/img/techny-artificial-intelligence-on-tablet-screen.gif"
import line from "../assets/img/line.png"

function About() {
    return (
        <div id="about" className="container about">
            <img src={line} />
            <div className="row">
                <div className="col-5 align-md">
                    <div>
                        <h3>
                            About
                        </h3>
                        <p>
                            At Artificial Agent, we're not just in the business of automation; we're in the business of transforming the way you connect with your audience. Picture an intelligent assistant that learns, adapts, and collaborates seamlessly with your team – that's the essence of what we bring to the table.
                        </p>
                        
                        <br/><br/>

                        <h3>
                            Why Us?
                        </h3>
                        <p>
                            Because your customer's journey deserves more than just automation. With Artificial Agent, it's about crafting personalized, engaging experiences. Elevate your interactions effortlessly—choose a partner that understands the heartbeat of your audience.                     
                        </p>
                    </div>
                </div>
                <div className="col-5">
                    <img src={aboutImg} className="about-img" alt="About" />
                </div>

            </div>
        </div>
    )
}

export default About;