export const data = [
    {
        id: 1,
        name: 'Start the Conversation',
        body: 'Begin your chat effortlessly on WhatsApp or webchat, connecting with our app.',
        img: 'https://baselinesandbox.co.za/Jacob/aa/quick-response.png'
    },{
        id: 2,
        name: 'Get Quick Answers',
        body: 'Our smart AI swiftly understands your messages, ensuring you get speedy responses.',
        img: 'https://baselinesandbox.co.za/Jacob/aa/start-chat.png'
    },{
        id: 3,
        name: 'Personalized Assistance',
        body: 'Whether it\'s a routine question answered by automation or a more personalized touch from a human, we seamlessly adapt to your needs.',
        img: 'https://baselinesandbox.co.za/Jacob/aa/personalised.png'
    },{
        id: 4,
        name: 'Engage & Resolve Together',
        body: 'Engage with our human agents for tailored assistance, ensuring your queries are resolved effectively.',
        img: 'https://baselinesandbox.co.za/Jacob/aa/engage-and-resolve.png'
    },{
        id: 5,
        name: 'Ongoing Improvement',
        body: 'Your experience matters. Our app learns from each interaction, evolving to provide you with even better service over time.',
        img: 'https://baselinesandbox.co.za/Jacob/aa/continous-improvement.png'
    },
]




