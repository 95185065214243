//import React from "react";
import React, { useState } from "react";
import logo from '../assets/img/logo.svg';

function Hamburger() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };
    return (
        <div>
            <div 
                className={`menu-overlay ${isOpen ? 'open' : ''}`}    
            >
                <img src={logo} />
                    <ul>
                        <li>
                            <a href="#about" className={`link ${isOpen ? 'is-active' : ''}`} onClick={toggleMenu}  id="dot-1">
                                About
                            </a>
                        </li>
                        <li>
                            <a href="#howItWorks" className={`link ${isOpen ? 'is-active' : ''}`} onClick={toggleMenu} id="dot-1">
                                How It Works
                            </a>
                        </li>
                        <li>
                            <a href="#useCase" className={`link ${isOpen ? 'is-active' : ''}`} onClick={toggleMenu} id="dot-1">
                                Use Cases
                            </a>
                        </li>
                        <li>
                            <a href="#contact" className={`link ${isOpen ? 'is-active' : ''}`} onClick={toggleMenu} id="dot-1">
                                Get In Touch
                            </a>
                        </li>
                    </ul>
            </div>
            <div 
                className={`hamburger ${isOpen ? 'is-active' : ''}`}
                id="hamburger-6"
                onClick={toggleMenu}
            >
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
            </div>
        </div>
    )
}

export default Hamburger;