import React from "react";

function Button() {
    return(
        <div>
            <a className="button" href="https://vercel.com/jacobbaselinedigitals-projects">
                Let's Chat
            </a>
        </div>
    )
}

export default Button;