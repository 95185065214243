import React from "react";
import Button from "./Button";

function HeaderContent() {
    return (
        <div>
            <div className="container header-content row">
                <div className="col-5">
                    <h1>
                        Prompt. Precise. <br/>Proficient.
                    </h1>
                    <p>
                        Elevate your business with Artificial Agent, a cutting-edge SAAS product designed to revolutionize the way you engage with your customers.
                    </p>
                </div>
                <div className="col-5">
                    <Button />
                </div>
            </div>
        </div>
    )
}

export default HeaderContent;