import React, { useEffect } from 'react';
import Header from './components/Header.js'
import HeaderContent from './components/HeaderContent.js';
import About from './components/About.js';
import HowItWorks from './components/HowItWorks.js';
import UseCases from './components/UseCases.js';
import Footer from './components/Footer.js';
import './App.css';


function App() {

  useEffect(() => {
    const path = document.querySelector('.path');

    if (path) {
      const length = path.getTotalLength();
      path.style.strokeDasharray = length + ' ' + length;
      path.style.strokeDashoffset = length;

      window.addEventListener('scroll', () => {
        const scrollPercentage = (document.documentElement.scrollTop + document.body.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
        const drawLength = length * scrollPercentage;

        path.style.strokeDashoffset = length - drawLength;
      });
    }
  }, []);

  return (
    <div className="App">
      {/* <div className='line-container'>
        <svg viewBox="0 0 1349 3565" fill="none" preserveAspectRatio="xMidYMax meet">
          <path className='path' d="M1348 0C1348 764.058 1348 956.407 1348 957.074H1V3564H393.623" stroke="#1A1A1A"/>
        </svg>
      </div> */}
      <Header />
      <HeaderContent />
      <About />
      <HowItWorks />
      <UseCases />
      <Footer />
    </div>
  );
}

export default App;
