import React from "react";
import { data } from "../data/HowToData";

function HowItWorks() {

    const slideLeft = () => {
        var slider = document.getElementById('slider');
        slider.scrollLeft = slider.scrollLeft - 400;
    };
    
    const slideRight = () => {
        var slider = document.getElementById('slider');
        slider.scrollLeft = slider.scrollLeft + 400;
    };

    return (
        <div id="howItWorks" className="container how-it-works">
            <div className="row">
                <div className="col-3">
                    <div>
                        <h3>
                            How It Works
                        </h3>
                        <p>
                            This dynamic collaboration between Artificial Agent and human agents ensures that your business delivers a responsive, personalized, and efficient customer experience across a spectrum of interactions.                        
                        </p>
                    </div>
                </div>
                <div className="col-7">
                    {/* Spacer */}
                </div>

            </div>
            <div className="row">
                <div className="col-3">
                    <div className="arrows-container">
                        <ul className="arrows">
                            <li>
                                <button onClick={slideLeft}>
                                    <span className="material-symbols-outlined">
                                        chevron_left
                                    </span>
                                </button>
                            </li>
                            <li>
                                <button onClick={slideRight}>
                                    <span className="material-symbols-outlined">
                                        chevron_right
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    
                </div>
                <div className="col-7 slide-container">
                    <div id="slider" className="slider">
                        {data.map((item)=>(
                                <div className="card-wrapper">
                                    <h3 className="card-id">{item.id}</h3>
                                    <h5 className="card-name">{item.name}</h5>
                                    <p className="card-body">{item.body}</p>
                                    <img className="card-img" src={item.img} alt="/" />
                                </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default HowItWorks;