export const useData = [
    {
        id: 1,
        name: 'Lead Generation Mastery',
        body: 'Say goodbye to cold outreach. Artificial Agent intelligently engages with potential leads, sparking meaningful conversations and converting prospects into valued customers.',
        img: 'https://baselinesandbox.co.za/Jacob/aa/techny-magnet-with-user-icons-and-target-with-arrow.png'
    },{
        id: 2,
        name: 'Effortless Customer Support',
        body: 'Deliver prompt and precise solutions to customer queries round the clock. Our AI learns from every interaction, ensuring proficiency in handling diverse support scenarios.',
        img: 'https://baselinesandbox.co.za/Jacob/aa/techny-support-chat.png'
    },{
        id: 3,
        name: 'Sales Acceleration',
        body: 'Streamline your sales process with a virtual assistant that understands your product inside out. From product inquiries to purchase assistance, Artificial Agent is your sales ally.',
        img: 'https://baselinesandbox.co.za/Jacob/aa/techny-financial-growth.png'
    },{
        id: 4,
        name: 'Survey and Feedback Automation',
        body: 'Collect valuable insights effortlessly. Artificial Agent streamlines the feedback process, engaging customers in post-purchase surveys or gauging employee satisfaction, providing you with actionable data for continuous improvement. ',
        img: 'https://baselinesandbox.co.za/Jacob/aa/techny-professional-portfolio-with-files-and-resume.png'
    },{
        id: 5,
        name: 'Market Research Assistance',
        body: 'Gain a competitive edge. Let Artificial Agent conduct market research, analyze trends, and provide insights into customer preferences, enabling data-driven decision-making for your business strategy.',
        img: 'https://baselinesandbox.co.za/Jacob/aa/techny-lettering-crm-with-graphs-gear-and-magnifier-text.png',
    },
]




