import React, { useRef } from "react";
import { useData } from "../data/UseCaseData";

    function UseCases() {

    const tabRefs = useRef(useData.map(() => React.createRef()));

    const handleDotClick = (index) => {
        tabRefs.current[index].current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
        });
    };

    return (
        <div id="useCase" className="container use-cases">
            <div className="row intro">
                <div className="col-4">
                    <div>
                        <h3>
                            Use Cases
                        </h3>
                        
                    </div>
                </div>
                <div className="col-6">
                    <p className="w-100">
                        At Artificial Agent, we understand that every business is unique. That's why our platform is more than just a chatbot – it's your personalized assistant. Tailor-made to fit your specific needs, our AI adapts to your industry, from generating leads to providing unparalleled technical support. 
                    </p>
                </div>

            </div>
            <div className="row flex">
                <div className="col-1 use-case ">
                    <ul className="dots">
                        <li>
                            <button className="dot" id="dot-1" onClick={() => handleDotClick(0)}>
                            </button>
                        </li>
                        <li>
                            <button className="dot" id="dot-1" onClick={() => handleDotClick(1)}>
                            </button>
                        </li>
                        <li>
                            <button className="dot" id="dot-1" onClick={() => handleDotClick(2)}>
                            </button>
                        </li>
                        <li>
                            <button className="dot" id="dot-1" onClick={() => handleDotClick(3)}>
                            </button>
                        </li>
                        <li>
                            <button className="dot" id="dot-1" onClick={() => handleDotClick(4)}>
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="col-9 scroll-tabs-wrapper" dir="ltr">
                    {useData.map((item, index)=>(
                        <div
                            key={item.id}
                            className={`tab ${index === 0 ? 'active' : ''}`}
                            id={`tab-${item.id}`}
                            ref={tabRefs.current[index]}
                        >
                            <div className="row">
                                <div className="col-6 use-case">
                                    <div>
                                        <h3>
                                            {item.name}
                                        </h3>
                                        <p>
                                            {item.body}
                                        </p>
                                    </div>
                                    
                                </div>
                                <div className="col-4 use-case">
                                    <img className="img-tab" src={item.img} alt="/" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default UseCases;