import React from "react";

function Footer() {
    return (
        <div id="contact" className="footer">
            <div className="row">
                <div className="col-10 jumbo-button-container">
                    <a className="jumbo-button" href="jp@baselinedigital.co.za">Let's talk</a>
                </div>
            </div>
            <div className="row footer-info">
                <div className="col-5">
                    <h3>Artificial Agent</h3>
                    <p>
                        At Artificial Agent, we're not just in the business of automation; we're in the business of transforming the way you connect with your audience. Picture an intelligent assistant that learns, adapts, and collaborates seamlessly with your team – that's the essence of what we bring to the table.
                    </p> 
                </div>
                <div className="col-1">
                    <h3>Social</h3>
                    <ul>
                        <li>
                            <a className="link" id="dot-1">
                                LinkedIn
                            </a>
                        </li>
                        <li>
                            <a className="link" id="dot-1">
                                WhatsApp
                            </a>
                        </li>
                        <li>
                            <a className="link" id="dot-1">
                                X
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-4">
                    <form className="form">
                        <input type="email" placeholder="Leave your email, we'll be in touch..."/>
                        <button type="submit" className="button">Email Me</button>
                    </form>
                </div>
            </div>
            <div className="row copyright">
                <p>
                    © 2023 Artificial Agent is a <a href="https://baselinedigital.co.za/">Baseline Digital Pty Ltd.</a> product. All rights reserved.
                </p>
                
                <p>
                    <a href="https://baselinedigital.co.za/">Credits</a>
                </p>
            </div>
        </div>
        
    )
}

export default Footer;