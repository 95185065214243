import React from 'react';
import logo from '../assets/img/logo.svg';
import Hamburger from './Hamburger';
import HeroVideo from './HeroVideo';

function Header() {
    return (
        <div className="Header">
            <HeroVideo />
            <header className="Header-wrapper">
                <img src={logo} className="App-logo" alt="logo" />
                <Hamburger />
            </header>
        </div>
    )
}

export default Header;

